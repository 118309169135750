<template>
  <div style="background-color: #e0e5f9;">
    <div class="row">
      <div
        class="col-md-12"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 2%;"
      >
        <!-- <img
          src="http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png"
          style="width: 10%; border-radius: 10px;"
        /> -->
        <h1 style="color: #0073c3; font-weight: 600;">Do not hesitate to contact us for anything you need</h1>
      </div>
      <div
        style="color: #0073c3"
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 contact-desc-block"
      >We are here to help you out at all times with optimum service for your convenience. For any queries please give us a ring or drop us a message</div>
    </div>
    <div class="row contact-cont-row" style="background-color: #e0e5f9; margin: auto;">
      <!-- <div class="col-lg-6 col-md-6 col-sm-6 contact-cont-left" style="padding: 2%;"> -->
        <!-- <div class="row">
                    <img  src="https://res.cloudinary.com/persystance-network/image/upload/v1589091973/slt/portal_user/1589091973023.png" style="width: 80%;">
                </div>
                <div class="row">
                    <div class="col-md-12 contact-det-block">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 contact-det-block-left">
                                <div class="row phone-email">
                                    <div class="col-lg-5 col-md-5 phone-block">
                                        <span class="simpal-bolt-text"><img  src="/assets/image/product/phone@2x.png" style="width: 25px; margin-right: 10px;">(+94) 768 172 295</span>
                                    </div>
                                    <div class="col-lg-7 col-md-7 email-block">
                                        <span class="simpal-bolt-text"><img  src="/assets/image/product/message@2x.png" style="width: 25px; margin-right: 10px;">orders@supiripola.lk</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <span class="simpal-bolt-text"><img  src="/assets/image/product/location@2x.png" style="width: 25px;  margin-right: 10px;">Level 33, West Tower, World Trade Centre, Colombo - 01</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 complaints-block">
                        <div class="row complaint-cont">
                            <div class="col-lg-5 col-md-5 complaint-left">
                                <span class="simpal-bolt-text">Complaints</span>
                            </div>
                            <div class="col-lg-7 col-md-7 complaint-right">
                                <div class="row ">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 complaint-number">
                                        <span class="simpal-bolt-text"><img  src="/assets/image/product/phone@2x.png" style="width: 25px; margin-right: 10px;">(+94) 764 406 085</span>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 complaint-email">
                                        <span class="simpal-bolt-text"><img  src="/assets/image/product/message@2x.png" style="width: 25px; margin-right: 10px;">cc1@supiripola.lk / cc2@supiripola.lk</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
        </div>-->
      <!-- </div> -->
      <div id="contactUs" class="col-lg-12 col-md-12 col-sm-12 contact-cont-right" style="padding: 2%; margin: auto;">
        <div class="form-content" style="background-color: #1472b8; border-radius: 25px;">
          <P class="simpal-text">Fill out this quick form and we will be in touch with you soon</P>
          <input
            onfocus="this.value=''"
            class="contact-inputs"
            type="text"
            v-model="basicInfo.name"
            placeholder="First name"
          />
          <input
            onfocus="this.value=''"
            class="contact-inputs"
            type="email"
            v-model="basicInfo.email"
            placeholder="Email"
          />
          <textarea
            onfocus="this.value=''"
            class="contact-inputs"
            rows="3"
            v-model="basicInfo.message"
            cols="12"
            placeholder="Your Message"
          ></textarea>
          <div>
            <span style="font-size: 16px; color: aliceblue;">Contact Us No : +94112389389</span>
            <button class="Submit-btn" @click="submitProfile">Submit</button>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 contact-cont-bottombg">
        <div class="contact-cont-bottombg-top"></div>
        <div class="contact-cont-bottombg-inner">
          <div class="info-block">
            <i class="fa fa-phone" aria-hidden="true"></i>1998
          </div>
          <div class="info-block">
            <a href="mailto:orders@supiripola.lk">
              <i class="fa fa-envelope" aria-hidden="true"></i>orders@lankasathosa.org
            </a>
          </div>
          <div class="info-block">
            <i class="fa fa-map-marker" aria-hidden="true"></i> 5th Floor, CWE Secretariat Building, Vauxhall Street, Colombo 02, Sri Lanka
          </div>
          <div class="info-block">
            <a target="_blank" href="https://www.lankasathosa.lk/">
              <i class="fa fa-globe" aria-hidden="true"></i> www.cochchi.lk
            </a>
          </div>
        </div>
        <div class="contact-cont-bottombg-bottom"></div>
      </div> -->
    </div>
  </div>
</template>
<script>
import APICommon from "@/mixins/APICommon.js";
export default {
  mixins: [APICommon],
  data: function() {
    return {
      isSltCustomer: false,
      basicInfo: {},
      merchantLog: localStorage.merchant_token,
    };
  },
  metaInfo: {
    title: "Contact Us",
    titleTemplate: "%s | Online Shopping Sri Lanka | Daily Essentials",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "cochchi.lk online shopping site in Sri Lanka is available for your Daily Essential Needs. Online Shopping Sri Lanka.Home Delivery (Within Delivery Grid)."
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  computed: {},
  created() {},
  methods: {
    submitProfile: async function() {
      try {
        let response = await this.putContactUs(this.basicInfo);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit success",
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit fail",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.basicInfo.name = "";
      this.basicInfo.email = "";
      this.basicInfo.message = "";
    }
  }
};
</script>
<style scoped>
#contactUs .form-content {
  color: black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 5%;
}


/* input, select, textarea{
    color: #ff0000;
} */

#contactUs .form-content input {
  color: black;
  margin: 3% 0%;
  background-color: #cfd5ff;
  font-size: 14px;
  padding: 1%;
  border-radius: 8px;
  border: 1px solid lightgray;
}

#contactUs .form-content textarea {
  color: black;
  margin: 3% 0%;
  background-color: #cfd5ff;
  border-radius: 8px;
  font-size: 14px;
  padding: 1%;
  border: 1px solid lightgray;
}

.Submit-btn {
  /* color: #000000; */
  font-size: 16px;
  text-align: center;
  /* background-color: #ffd300; */
  border-radius: 8px;
  font-weight: 600;
  background: linear-gradient(180deg, #f79515 0%, #736165 100%);;
  color: #ffffff;
  max-width: 40%;
  padding: 2%;
  float: right;
  border: none;
}

.Submit-btn:hover,
.Submit-btn:focus {
  color: black;
  outline: none !important;
}

*:focus {
  outline: none;
}

.simpal-text {
  font-size: 18px;
  font-weight: 300;
  color: white;
  line-height: 1.35;
}

.simpal-bolt-text {
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  color: black;
  line-height: 1.35;
}
.contact-desc-block {
  text-align: center;
  font-size: 18px;
  margin-top: 12px;
  margin-bottom: 25px;
}
.contact-det-block {
  padding: 25px 0px 25px 0px;
}
.contact-det-block .phone-email {
  margin: 0px;
}
.contact-det-block .phone-block {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 15px;
}
.contact-det-block .email-block {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 15px;
}
.complaints-block {
  padding: 25px 0px 25px 0px;
  border-top: 1px solid #dedede;
}
.complaints-block .complaint-cont {
  margin: 0px;
}
.complaints-block .complaint-left {
  padding-left: 5%;
  padding-right: 0px;
  padding-bottom: 15px;
}
.complaints-block .complaint-right {
  padding-left: 0px;
  padding-right: 0px;
}
.complaints-block .complaint-right .complaint-number {
  margin-bottom: 15px;
}

/* New UI Styles */
.contact-cont-row {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  background: #ffffff;
}
.contact-cont-row .contact-cont-left {
  flex-basis: 50%;
  order: 1;
}
.contact-cont-row .contact-cont-right {
  flex-basis: 50%;
  order: 2;
}
.contact-cont-row .contact-cont-bottombg {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: flex-end;
  order: 3;
  flex-wrap: wrap;
  min-height: 330px;
  margin-top: -200px;
}
.contact-cont-bottombg-top {
  flex-basis: 100%;
  min-height: 292px;
  position: relative;
  z-index: 15;
  /* background: url("../../assets/image/contactnewui/contactus_footer_sathosa_striptop.png") */
    /* no-repeat center bottom; */
}
.contact-cont-bottombg-inner {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  min-height: 70px;
  background: #808080;
  color: #ffffff;
  position: relative;
  z-index: 10;
  margin-top: -11px;
}
.contact-cont-bottombg-bottom {
  flex-basis: 100%;
  min-height: 44px;
  position: relative;
  z-index: 15;
  /* background: url("../../assets/image/contactnewui/contactus_footer_sathosa_stripbottom.png") */
    /* no-repeat center bottom; */
}
.contact-cont-bottombg-inner a {
  color: #ffffff;
}
.contact-cont-bottombg-inner a:hover {
  text-decoration: none;
  color: #de1a22;
}
.contact-cont-bottombg-inner a:focus {
  text-decoration: none;
}
.contact-cont-bottombg-inner .fa {
  color: #f7e84b;
  margin-right: 12px;
  font-size: 20px;
}
.contact-cont-bottombg-inner .info-block {
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid #f7e84b;
  /* outline: 1px solid green; */
}
.contact-cont-bottombg-inner .info-block:last-child {
  border-right: none;
}

@media screen and (max-width: 575px) {
  .contact-det-block .simpal-bolt-text {
    font-size: 14px;
  }
  .complaints-block .simpal-bolt-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  .complaints-block .complaint-left {
    padding-left: 0px;
  }
  /* New queries */
  .contact-cont-row .contact-cont-left {
    display: none;
  }
  .contact-cont-row .contact-cont-right {
    flex-basis: 100%;
    order: 1;
  }
  .contact-cont-row .contact-cont-bottombg {
    order: 2;
    margin-top: 35px;
  }
  .contact-cont-bottombg-inner {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 11px;
  }
  .contact-cont-bottombg-inner .info-block {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .contact-cont-bottombg-top {
    background: url("../../assets/image/contactnewui/contactus_footer_sathosa_striptop.png")
      no-repeat 15px bottom;
  }
}
@media screen and (max-width: 475px) {
  .contact-cont-bottombg-top {
    background: url("../../assets/image/contactnewui/contactus_footer_sathosa_striptop.png")
      no-repeat -50px bottom;
  }
}
@media screen and (max-width: 425px) {
  .contact-det-block .simpal-bolt-text {
    font-size: 12px;
  }
  .complaints-block .simpal-bolt-text {
    font-size: 12px;
  }
  .contact-det-block img {
    width: 17px !important;
  }
  .complaints-block img {
    width: 17px !important;
  }
  .contact-cont-bottombg-inner .info-block {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1675px) {
  .contact-cont-bottombg-inner {
    font-size: 13px;
  }
}
</style>